import { title } from "@core/utils/filter";

export default [
  {
    key: "selected",
    label: "",
    sortable: false,
    visible: true,
  },
  {
    key: "nickname",
    label: "Nickname",
    sortable: true,
    formatter: title,
    visible: true,
  },
  {
    key: "state_hour",
    label: "ST/AD",
    sortable: true,
    visible: true,
  },
  {
    key: "source",
    label: "Source",
    sortable: false,
    visible: true,
  },
  {
    key: "mobile",
    label: "Mobile",
    sortable: true,
    visible: true,
  },
  {
    key: "fanpage",
    label: "Program",
    sortable: false,
    visible: true,
  },
  {
    key: "credit_report",
    label: "CR",
    sortable: false,
    visible: true,
  },
  {
    key: "sale_lead_status",
    label: "Sale",
    sortable: false,
    visible: true,
  },
  {
    key: "created_at",
    label: "Created at",
    sortable: true,
    visible: true,
  },
  {
    key: "owner",
    label: "Owner",
    sortable: true,
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    sortable: false,
    visible: true,
  },
  {
    key: "client_ceo",
    label: "client of ceo",
    sortable: false,
    visible: true,
  },
  {
    key: "potential",
    label: "Potential",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
    visible: true,
  },
];
